import { ShiftSpecificationCategory, TimeSlot } from "@src/appV2/Shifts/types";
import { Agent } from "./agent";
import { CBHDocument } from "./document";
import { GeoLocation } from "./util";
import { ExtraWorkedTimeType } from "@src/appV2/ExtraWorkedTimeRequests/types";

// TODO: Find a way to share this between backend and frontend
// This is where you can find it in the backend: src/models/shift.type
enum ShiftVerificationType {
  AUTHORIZED_SIGNATORY = "AUTHORIZED_SIGNATORY",
  ADMIN_VERIFICATION = "ADMIN_VERIFICATION",
  AUTO_VERIFY = "AUTO_VERIFY",
}

// TODO: Find a way to share this between backend and frontend
// This is where you can find it in the backend: src/constants/timesheetMethods
enum TIMESHEET_TYPE {
  PHOTO = "photo",
  CDPH530 = "CDPH-530",
}

// TODO: Find a way to share this between backend and frontend
// This is where you can find it in the backend: src/constants/instantPay
enum INSTANT_PAY_USER {
  HCF = "HCF",
  HCP = "HCP",
}

enum ShiftName {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export function shiftNameFromTimeSlot(timeSlot: TimeSlot): ShiftName | undefined {
  const mapping: Record<TimeSlot, ShiftName | undefined> = {
    am: ShiftName.AM,
    pm: ShiftName.PM,
    noc: ShiftName.NOC,
  };

  return mapping[timeSlot];
}

enum SignatoryStatus {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  SIGNED = "SIGNED",
}

enum ShiftInOut {
  CLOCK = "clockInOut",
  LUNCH = "lunchInOut",
}

enum ShiftActivityType {
  SHIFT_OPEN = "SHIFT_OPEN",
  SHIFT_ASSIGN = "SHIFT_ASSIGN",
  SHIFT_REASSIGN = "SHIFT_REASSIGN",
  SHIFT_UNASSIGN = "SHIFT_UNASSIGN",
  SHIFT_DELETE = "SHIFT_DELETE",
  SHIFT_DELETE_FILLED = "SHIFT_DELETE_FILLED",
  FACILITY_CANCEL = "FACILITY_CANCEL",
  FACILITY_CANCEL_FILLED = "FACILITY_CANCEL_FILLED",
  WORKER_CANCEL = "WORKER_CANCEL",
  NO_CALL_NO_SHOW = "NO_CALL_NO_SHOW",
  SHIFT_CLAIM = "SHIFT_CLAIM",
  SHIFT_VERIFY = "SHIFT_VERIFY",
  CLOCK_IN_OUT = "CLOCK_IN_OUT",
  LUNCH_IN_OUT = "LUNCH_IN_OUT",
}

interface VerificationPreferenceDetailsProps {
  /**
   * When you are fetching verification preference for a single facility, use this.
   */
  facilityId?: string;
  /**
   * When a list of facilities are rendered, we should pass this property to avoid
   * making different requests for each resource.
   */
  verificationPreference?: VerificationPreferences;
  // Used in unferifiedSHiftListCard.tsx for extra condition
  timeSheetExists?: any;
}

export interface ExtraWorkedBreakTimeConfig {
  isAutoDeclinationEnabled?: boolean;
  autoDeclinationReason?: string;
}

export interface FacilityExtraWorkedTimeConfig {
  [ExtraWorkedTimeType.BREAK]?: ExtraWorkedBreakTimeConfig;
}

interface Facility {
  _id?: string;
  userId?: string;
  parentFacilityId?: string;
  name?: string;
  phone?: string;
  email?: string;
  pricingMsa?: string;
  fullAddress?: Partial<{
    line1: string;
    streetNumber: string;
    streetName: string;
    region: string;
    city: string;
    metropolitanStatisticalArea: string;
    manualMsa: boolean;
    state: string;
    country: string;
    stateCode: string;
    countryCode: string;
    postalCode: string;
    formatted: string;
  }>;
  status?: string;
  tmz?: string;
  distance?: number;
  isApproxDistance?: boolean;
  description?: string;
  profileDescription?: string;
  authorizedSignatories?: AuthorizedSignatory[];
  geoLocation?: GeoLocation;
  instantBook?: boolean | string;
  requiredDocuments?: CBHDocument[];
  rates?: {
    CNA?: number;
    LVN?: number;
    RN?: number;
    NP?: number;
    PT?: number;
    PTA?: number;
    HHA?: number;
  };
  ratesTable?: {
    sunday: { am: number; pm: number; noc: number };
    monday: { am: number; pm: number; noc: number };
    tuesday: { am: number; pm: number; noc: number };
    wednesday: { am: number; pm: number; noc: number };
    thursday: { am: number; pm: number; noc: number };
    friday: { am: number; pm: number; noc: number };
    saturday: { am: number; pm: number; noc: number };
  };
  checkInInstructions?: string;
  isInstantPayEnabled?: boolean;
  agentHasAllDocuments?: boolean;
  verification?: VerificationPreferences;
  verificationPreference?: VerificationPreferences;
  facilityNotes?: unknown[];
  locationAwareness?: string;
  profileDecriptionlastManualUpdate?: Date;
  type?: string;
  shiftsCount?: number;
  filteredShiftsCount?: number;
  shifts?: Shift[];
  rating?: {
    count: number;
    value: number;
  };
  requireTimecardPhoto?: boolean;
  is100InstantPayEnabled?: boolean;
  lateCancellation?: {
    period: number;
    feeHours: number;
  };
  nfcTag?: boolean;
  federalProviderNumber?: string;
  note?: string;
  coordinators?: Array<{
    name: string;
    phone: string;
    email: string;
    notes: string;
  }>;
  payroll?: { name: string; email: string };
  notifyShiftAssignment?: boolean;
  isTestAccount?: boolean;
  numberOfBeds?: string;
  chartingType?: string;
  additionalDetails?: string;
  salesforceID?: string;
  location?: string;
  payOnHoliday?: boolean;
  holidayList?: string[];
  payOnAllHolidays?: boolean;
  holidayFee?: {
    type: string;
    increment: number;
  };
  rateNegotiation?: RateNegotiationConfigEnum;
  variableCharge?: {
    enabled: boolean;
  };
  extraWorkedTimeConfig?: FacilityExtraWorkedTimeConfig;
  providesRestBreaks?: boolean;
}

export enum RateNegotiationConfigEnum {
  ON = "ON",
  OFF = "OFF",
}

type HolidayIncrement = "FLAT" | "CHARGE_RATE_MULTIPLIER";
enum VerificationMethod {
  TIMESHEET = "Timesheet Only",
  OUTBOUND = "Outbound Verification",
  SHEET = "External Google Sheet",
  OTHER = "Other",
}
type ContactMethod = "PHONE" | "EMAIL" | "TEXT";
type Day = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
interface ContactAvailability {
  day: Day;
}
interface VerificationContact {
  archived: boolean;
  contactMethod: {
    name: ContactMethod;
  };
  availability: ContactAvailability[];
}
export interface VerificationPreferences {
  mongodbId: string;
  name: string;
  maxOutreachAttemptsPerDay: number;
  usesTimesheets: boolean;
  verificationMethod: {
    name: VerificationMethod;
  };
  contacts: VerificationContact[];
  outboundContactList?: VerificationContact[];
  preferenceType?: string;
  verificationAttempt?: number;
  requiresLunchBreak?: boolean;
}

interface AuthorizedSignatory {
  _id?: string;
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
}

interface RequestedSignatory extends AuthorizedSignatory {
  status: SignatoryStatus;
}

interface TimeRange {
  startCard?: string;
  endCard?: string;
  start?: string;
  end?: string;
  isSkipped?: boolean;
  startImage?: string;
  endImage?: string;
}

interface DisputedTimeRange {
  stamp: Date;
  stage: string;
}

interface TimecardFiles {
  _id: string;
  filename: string;
  url: string;
  fileStorageFileKey?: string;
  timesheet?: TIMESHEET_TYPE;
  signedType?: INSTANT_PAY_USER;
}
interface Timecard {
  createdAt?: Date;
  files?: TimecardFiles[];
  status?: number;
  digitallySignedBy?: string;
}

interface TimecardV2 extends Timecard {
  success: boolean;
  error: string | null;
  response: object;
}

interface SurgerySpeciality {
  speciality: string;
  hasTrayAssemblyExperience: boolean;
  hasSedationExperience: boolean;
}

/**
 * Most of these enums were copied from backend-main/src/constants or similar project files
 * We should centralize them here in the future
 */

enum InstantPayPaymentTypes {
  INITIAL = "INITIAL",
  REMAINING = "REMAINING",
}

enum InstantPayPaymentVia {
  STRIPE = "STRIPE",
  DAILY_PAY = "DAILY_PAY",
}

enum InstantPayTimeLogStates {
  ASSIGNED = "ASSIGNED",
  ASSIGNED_FAILED = "ASSIGNED_FAILED",
  CLOCK_IN = "CLOCK_IN",
  CLOCK_OUT = "CLOCK_OUT",
  LUNCH_IN = "LUNCH_IN",
  LUNCH_OUT = "LUNCH_OUT",
  ADMIN_CLOCK = "ADMIN_CLOCK",
  ADMIN_LUNCH = "ADMIN_LUNCH",
  PARTIALY_PAID = "PARTIALY_PAID",
  FULL_PAID = "FULL_PAID",
  CANCELED = "CANCELLED",
  VERIFIED = "VERIFIED",
  TIME_ADJUSTED = "TIME_ADJUSTED",
  SKIP_LUNCH = "SKIP_LUNCH",
  SHIFT_NOTE_UPDATE = "SHIFT_NOTE_UPDATE",
  SHIFT_TIME_CHANGE = "SHIFT_TIME_CHANGE",
  POST_VERIFY_CLOCK_IN = "POST_VERIFY_CLOCK_IN",
  POST_VERIFY_CLOCK_OUT = "POST_VERIFY_CLOCK_OUT",
  POST_VERIFY_LUNCH_IN = "POST_VERIFY_LUNCH_IN",
  POST_VERIFY_LUNCH_OUT = "POST_VERIFY_LUNCH_OUT",
}

enum ShiftGeofenceStatus {
  ENTRY = "entry",
  EXIT = "exit",
  START_BREAK = "start-break",
  END_BREAK = "end-break",
}

interface ShiftGeofence {
  entry: Date;
  exit?: Date;
  startBreak?: Date;
  endBreak?: Date;
  status: ShiftGeofenceStatus;
}

interface InstantPayShiftPayment {
  amount: number;
  paidVia: InstantPayPaymentVia;
  type: InstantPayPaymentTypes;
}

interface InstantPayShiftDetails {
  shiftId: string;
  hcpId: string;
  start: string;
  end: string;
  verified: boolean;
  deleted: boolean;
  status?: InstantPayTimeLogStates;
  action: InstantPayTimeLogStates;
  payments: InstantPayShiftPayment[];
  charge: number;
  time: number;
  pay: number;
  totalAmount: number;
  paidAmount: number;
  remaingAmount: number;
  hcpClockIn?: string;
  hcpLunchOut?: string;
  hcpLunchIn?: string;
  hcpClockOut?: string;
  adminClockIn?: string;
  adminLunchIn?: string;
  adminLunchOut?: string;
  adminClockOut?: string;
  completedDate?: string;
  postVerifyClockIn?: string;
  postVerifyClockOut?: string;
  postVerifyLunchIn?: string;
  postVerifyLunchOut?: string;
  is100InstantPayEnabled?: boolean | undefined;
}

interface ShiftAttendance {
  leftFacilityGeofence: boolean;
  leftFacilityGeofenceAt: Date;
  arrivedFacilityGeofenceAt: Date;
  isWorkerFarFromFacility: boolean | undefined;
}

export interface ShiftLengthAdjustmentInputs {
  clockIn?: Date;
  clockOut?: Date;
  lunchIn?: Date;
  lunchOut?: Date;
  skippedLunch?: boolean;
}

export interface ShiftSpecifications {
  category: ShiftSpecificationCategory;
  requirement: string;
}

interface Shift {
  _id: string;
  name?: ShiftName;
  verified?: boolean;
  signed?: boolean;
  agentReq?: string;
  confirmed?: boolean;
  filtered?: boolean;
  start: string;
  end: string;
  time?: number;
  description?: string;
  surgerySpeciality?: SurgerySpeciality;
  geofence?: ShiftGeofence;
  charge?: number;
  pay?: number;
  distance?: number;
  isApproxDistance?: boolean;
  finalPay?: number;
  facility?: Facility;
  facilityId?: string;
  agentId?: string | string;
  agent?: Agent;
  requestedSignatories?: RequestedSignatory[];
  interested?: Agent[] & string[];
  signatory?: AuthorizedSignatory;
  unit?: string;
  clockInOut?: TimeRange;
  lunchInOut?: TimeRange;
  disputedTimeout?: DisputedTimeRange;
  timecard?: Timecard;
  isHolidayShift?: boolean;
  holidayName?: string;
  canRequestReplacement?: boolean;
  rating?: {
    FACILITY?: number;
    AGENT?: number;
  };
  cancellationReason?: {
    type: string;
  };
  status?: number;
  isHCFInstantPayProhibited?: boolean;
  instantPayDetails?: InstantPayShiftDetails;
  agentHasAllDocuments?: boolean;
  isInstantPayEnabled?: boolean;
  shiftId?: string;
  isInstantBook?: boolean;
  isInstantPay?: boolean;
  isChangedToNonInstantPay?: boolean;
  instruction?: string;
  isHCFConfirmed?: boolean;
  isInstantForAgent?: boolean;
  isInstantPayWorkedBefore?: boolean;
  groupId?: string;
  urgency?: number;
  hidden?: number;
  bookable?: boolean;
  timecardNotAvailable?: {
    reason: string;
    setBy: string;
    updatedAt: string;
  };
  payBreakDown?: {
    boostPay?: number;
    basePay?: number;
    configuredPay?: number;
    urgentPay?: number;
    holiday?: number;
  };
  isStripePayment?: boolean;
  reminderNotify?: [];
  submitClockInOut?: TimeRange;
  submitLunchInOut?: TimeRange;
  autoClockedOut?: boolean;
  deleted?: boolean;
  isBillable?: boolean;
  nfcTag?: boolean;
  holidayIncrement?: {
    type: HolidayIncrement;
    increment: number;
  };
  verification?: {
    method: ShiftVerificationType;
  };
  originalAmount?: number;
  isFCFRefill?: boolean;
  isBookedSameShift?: boolean;
  chargeBreakDown?: ChargeBreakDownType;
  byFacility?: boolean;
  payInheritanceLevel?: string;
  note?: string;
  interestedHcps?: object;
  externalId?: string;
  assignedBy?: string;
  missingDocs?: object;
  originalReq?: string;
  selfClaim?: boolean;
  priorityTill?: string;
  createdAt?: Date;
  updatedAt?: Date;
  attendance?: ShiftAttendance;
  extendBookabilityTo?: string;
  latestClockInOutAdjustment?: ShiftLengthAdjustmentInputs;
  urgencyReason?: string;
  isMagicShift?: boolean;
  nursePayBreakDown?: NursePayBreakDownType;
  nurseChargeBreakDown?: NurseChargeBreakDownType;
  invoiceId?: string;
  createdFrom?: string;
  invoiceNumber?: string;
  shiftBlockId?: string;
  invoiceItemNote?: string;
  shiftSpecifications?: ShiftSpecifications[];
  hasFixedPay?: boolean;
  extendedBookabilityTo?: string;
}

export interface PayBreakDownType {
  api?: number;
  basePay?: number;
  boostPay?: number;
  configuredPay?: number;
  hazard?: number;
  holiday?: number;
  manual?: number;
  negotiatedPay?: number;
  urgentPay?: number;
}

export interface NursePayBreakDownType {
  LVN: PayBreakDownType;
  RN: PayBreakDownType;
}

export interface NurseChargeBreakDownType {
  LVN: ChargeBreakDownType;
  RN: ChargeBreakDownType;
}

export interface ChargeBreakDownType {
  baseCharge: number;
  hazard: number;
  holiday: number;
  time: number;
  rush: number;
  manual: number;
  api: number;
  negotiatedCharge?: number;
  maxChargeCredit?: number;
}

interface Shift_NEW {
  response: [] | object | null;
  error: string | null;
  success: boolean;
  instantPayDetails?: InstantPayShiftDetails;
  agentHasAllDocuments?: boolean;
}

interface UnverifiedShift extends Shift {
  businessrules?: {
    key: string;
    value: string;
    description: string;
  };
}

enum SHIFT_MARKED_NON_IP_REASONS {
  NO_LOCATION = "HCP failed to confirm location",
  MOBILE_FORGOT_TO_CLOCK_IN = "Mobile: HCP failed to clock in",
  MOBILE_HCP_IS_OUTSIDE_GEOFENCE = "Mobile: HCP is outside geofence and failed to clock in",
  API_CALL = "/markNonInstant API call",
  CAUGHT_ON_AUTO_CLOCK_OUT = "HCP forgot to clock in, caught on auto clock out",
  REACHED_VERIFICATION_WITHOUT_PAYMENT = "SUSPICIOUS, Reached the verification step without initial payment",
  FORGOT_TO_CLOCK_OUT = "HCP auto clocked out",
  FORGOT_TIMESHEET = "HCP forgot timesheet",
}

enum ShiftStages {
  CLOCK_IN = "CLOCK_IN",
  LUNCH_IN = "LUNCH_IN",
  LUNCH_OUT = "LUNCH_OUT",
  CLOCK_OUT = "CLOCK_OUT",
  SHIFT_TIME_DONE = "SHIFT_TIME_DONE",
  SKIP_LUNCH = "SKIP_LUNCH",
  UPLOAD_TIME_CARD = "UPLOAD_TIME_CARD",
  GET_FACILITY_SIGNATURE = "GET_FACILITY_SIGNATURE",
}

export type {
  Shift,
  AuthorizedSignatory,
  RequestedSignatory,
  Facility,
  TimeRange,
  Timecard,
  TimecardFiles,
  Shift_NEW,
  TimecardV2,
  UnverifiedShift,
  VerificationPreferenceDetailsProps,
  InstantPayShiftDetails,
  InstantPayShiftPayment,
};

export {
  ShiftGeofenceStatus,
  ShiftName,
  SignatoryStatus,
  ShiftInOut,
  ShiftActivityType,
  SHIFT_MARKED_NON_IP_REASONS,
  InstantPayTimeLogStates,
  InstantPayPaymentVia,
  InstantPayPaymentTypes,
  VerificationMethod,
  ShiftStages,
};
