// This snippet is taken from here: https://segment.com/blog/intro-to-segment-middleware-analytics-js/
interface Page {
  url: string;
  search: string;
  referrer: string;
}

interface MiddlewarePayload {
  obj: {
    context: { page: Page };
    properties: Page;
  };
}

interface SourceMiddlewareParams {
  payload: MiddlewarePayload;
  next: (payload: MiddlewarePayload, integrations: unknown) => void;
  integrations: unknown;
}

function cleanPageContext(page: Page) {
  const { url, search, referrer } = page;
  return {
    ...(url && { url: url.replace(/&?oobCode=\w+/g, "") }),
    ...(search && { search: search.replace(/&?oobCode=\w+/g, "") }),
    ...(referrer && { referrer: referrer.replace(/&?oobCode=\w+/g, "") }),
  };
}

const invokeSegment = (segmentKey) => {
  // eslint-disable-next-line no-multi-assign, @typescript-eslint/no-explicit-any
  const analytics: any = (window.analytics = window.analytics || []);
  if (!analytics.initialize) {
    if (analytics.invoked) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.console && console.error && console.error("Segment snippet included twice.");
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      analytics.factory = function (e) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        const t = document.createElement("script");
        t.type = "text/javascript";
        t.async = !0;
        t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
        const n = document.getElementsByTagName("script")[0] as HTMLScriptElement;
        n.parentNode?.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = segmentKey;
      analytics.SNIPPET_VERSION = "4.15.3";
      analytics.load(segmentKey);

      // filter out sensitive data from the context sent to Segment
      analytics.addSourceMiddleware(({ payload, next, integrations }: SourceMiddlewareParams) => {
        try {
          if (payload.obj?.context?.page) {
            payload.obj.context.page = {
              ...payload.obj.context.page,
              ...cleanPageContext(payload.obj.context.page),
            };
          }

          if (payload.obj?.properties) {
            payload.obj.properties = {
              ...payload.obj.properties,
              ...cleanPageContext(payload.obj.properties),
            };
          }

          next(payload, integrations);
        } catch (error) {
          next(payload, integrations);
        }
      });
    }
  }
};

export { invokeSegment };
