import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { environmentConfig } from "@src/appV2/environment";
import { post } from "@src/appV2/api";
import { z } from "zod";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";

interface InvitePlacementApplicationCandidateToInterviewParams {
  placementApplicationId: string;
  workplaceId: string;
}

const invitePlacementApplicationCandidateToInterviewBodySchema = z.object({
  data: z.object({
    type: z.literal("placement-application"),
    id: z.string(),
  }),
});

export function useInvitePlacementApplicationCandidateToInterview(
  options: UseMutationOptions<
    void,
    string,
    InvitePlacementApplicationCandidateToInterviewParams
  > = {}
) {
  return useMutation({
    mutationFn: async (params: InvitePlacementApplicationCandidateToInterviewParams) => {
      const { placementApplicationId, workplaceId } = params;

      await post({
        url: `${environmentConfig.REACT_APP_API_MAIN_URL}/api/workplaces/${workplaceId}/placement-applications/${placementApplicationId}/interviews/invites`,
        data: {
          data: {
            type: "placement-application",
            id: placementApplicationId,
          },
        },
        responseSchema: z.any(), // This API returns 204 No Content, with an empty body
        requestSchema: invitePlacementApplicationCandidateToInterviewBodySchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_AUTO_INVITE_SEND_FAILURE,
      userSuccessMessage: "Your message was sent successfully.",
      userErrorMessage: "Failed to send message",
    },
    ...options,
  });
}
