import { isDefined } from "@clipboard-health/util-ts";
import { z } from "zod";
import { calculateDuration } from "./utils";
import meta from "src/utils/meta";

const { maxShiftDuration, minShiftDuration } = meta();

export enum ShiftType {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export enum DateSelectionViewType {
  Calendar = "calendar",
  Week = "week",
}

export function stringToDateSelectionViewType(value: string | null): DateSelectionViewType {
  if (value === DateSelectionViewType.Week) {
    return DateSelectionViewType.Week;
  }
  return DateSelectionViewType.Calendar;
}

export const DateSelectionViews = [
  { label: "Calendar", value: DateSelectionViewType.Calendar },
  { label: "Week", value: DateSelectionViewType.Week },
];

export const ShiftTypes = [
  { label: "AM", value: ShiftType.AM },
  { label: "PM", value: ShiftType.PM },
  { label: "NOC", value: ShiftType.NOC },
];

export enum DaysOfTheWeek {
  Sunday = "1",
  Monday = "2",
  Tuesday = "3",
  Wednesday = "4",
  Thursday = "5",
  Friday = "6",
  Saturday = "7",
}

export const daysOfTheWeek = [
  { label: "Sun", value: DaysOfTheWeek.Sunday },
  { label: "Mon", value: DaysOfTheWeek.Monday },
  { label: "Tue", value: DaysOfTheWeek.Tuesday },
  { label: "Wed", value: DaysOfTheWeek.Wednesday },
  { label: "Thu", value: DaysOfTheWeek.Thursday },
  { label: "Fri", value: DaysOfTheWeek.Friday },
  { label: "Sat", value: DaysOfTheWeek.Saturday },
];

export const MAX_SHIFT_COPIES = 31;

export const postShiftSchema = z
  .object({
    workerType: z.string({
      required_error: "Please select a worker type",
      invalid_type_error: "Please select a worker type",
    }),
    shiftCopies: z.coerce
      .number({
        required_error: "Please enter required number of shift copies",
        invalid_type_error: "Please enter required number of shift copies",
      })
      .min(1, "Number of copies should be at least 1")
      .max(MAX_SHIFT_COPIES, `Number of copies should be at most ${MAX_SHIFT_COPIES}`),

    shiftType: z.nativeEnum(ShiftType, {
      required_error: "Please select a shift type",
      invalid_type_error: "Please select a shift type",
    }),
    startTime: z.date(),
    endTime: z.date(),

    dateSelectionView: z.nativeEnum(DateSelectionViewType),
    recurring: z.boolean(),
    days: z.array(z.nativeEnum(DaysOfTheWeek)).optional(),
    startDate: z.date().optional(),
    endDate: z.date().optional(),
    saveTimeConfiguration: z.boolean().optional(),
    selectedDates: z.array(z.date()).optional(),
  })
  .refine(
    (data) => {
      if (data.startTime && data.endTime) {
        const totalShiftHours = calculateDuration(data.startTime, data.endTime);
        if (isDefined(totalShiftHours)) {
          const durationInHours = totalShiftHours.hours + totalShiftHours.minutes / 60;
          if (durationInHours > maxShiftDuration) {
            return false;
          } else if (durationInHours < minShiftDuration) {
            return false;
          }
        }
      }
      return true;
    },
    {
      message: `Shift duration must be ${minShiftDuration}-${maxShiftDuration} hours.`,
      path: ["shiftType"],
    }
  )
  .refine(
    (data) => {
      return data.dateSelectionView === DateSelectionViewType.Calendar
        ? isDefined(data.selectedDates) && data.selectedDates.length > 0
        : true;
    },
    {
      message: "Please select a date to post shifts.",
      path: ["selectedDates"],
    }
  )
  .refine(
    (data) => {
      return data.dateSelectionView === DateSelectionViewType.Week
        ? isDefined(data.startDate)
        : true;
    },
    {
      message: "Please select a start date.",
      path: ["selectedDates"],
    }
  )
  .refine(
    (data) => {
      return data.recurring === true ? isDefined(data.days) && data.days.length > 0 : true;
    },
    {
      message: "Please select a shift days",
      path: ["days"],
    }
  )
  .refine(
    (data) => {
      return data.recurring === true ? isDefined(data.endDate) : true;
    },
    {
      message: "Please select an end date",
      path: ["endDate"],
    }
  );

export type PostShiftForm = z.infer<typeof postShiftSchema>;
