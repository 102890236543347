import { getUpcomingShiftsGrouped } from "../containers/chat/chatApi";
import moment from "moment-timezone";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import { Shift } from "src/interface";
import { ChatChannel } from "@src/containers/chat/chat.types";

export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const SET_CHANNELS = "SET_CHANNELS";
export const SET_UPCOMING_SHIFTS = "SET_UPCOMING_SHIFTS";

const initialState: { channels: Array<GroupChannel & { shift?: Shift }> } = {
  channels: [],
};

const sortChannels = (list) => {
  return list.sort((a, b) => {
    if (a.unreadMessageCount && b.unreadMessageCount) {
      return b.unreadMessageCount - a.unreadMessageCount;
    }

    if (a.unreadMessageCount) {
      return -1;
    }
    if (b.unreadMessageCount) {
      return 1;
    }

    if (a.shift && b.shift) {
      return moment(a.shift.start).diff(b.shift.start);
    }
    if (a.shift) {
      return -1;
    }
    if (b.shift) {
      return 1;
    }

    const a1 = a.lastMessage ? a.lastMessage.createdAt : a.createdAt;
    const b1 = b.lastMessage ? b.lastMessage.createdAt : b.createdAt;

    return b1 - a1;
  });
};

const setMetadata = (list) => {
  for (const item of list) {
    const metadata = (item.cachedMetaData as ChatChannel["metadata"]) ?? {};
    const {
      facilityName,
      hcpName,
      lastBooked,
      agentReq,
      placementCandidateAccessId,
      placementId,
      placementTitle,
      managingFacilityId,
    } = metadata;

    item.metadata = {
      facilityName,
      hcpName,
      lastBooked,
      agentReq,
      placementCandidateAccessId,
      placementId,
      placementTitle,
      managingFacilityId,
    };
  }
};

/**
 * Redux state management and other boilerplate code is painful to manage and hinders discoverability.
 * Redux code guidelines show this bizarre pattern with initializing function arguments
 * without a default for the action. Example:
 * https://redux.js.org/usage/structuring-reducers/initializing-state#single-simple-reducer
 * This is linted against via typical lint rules.
 * Unfortunately, redux recommends this, so we need to mark the lint rule as disabled until
 * we eliminate redux from the code base.
 * https://linear.app/clipboardhealth/issue/FEF-167/deprecate-redux
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANNELS: {
      const newChannels = action.data;
      setMetadata(newChannels);

      const oldChannels = state.channels;
      return {
        channels: sortChannels(
          newChannels.map((channel) => {
            const oldChannel = oldChannels.find((oldItem) => oldItem.url === channel.url);
            if (oldChannel) {
              // Adding a spread operator is adding all the getters and setters to the object.
              // So serializing the channel before adding new properties to it.
              return { ...channel.serialize(), shift: oldChannel.shift };
            }

            return channel.serialize();
          })
        ),
      };
    }
    case UPDATE_CHANNEL: {
      const updatedChannel = action.data;
      const foundChannel = state.channels.find((item) => item.url === updatedChannel.url);
      if (foundChannel) {
        return {
          channels: sortChannels(
            state.channels.map((item) => {
              return item.url === updatedChannel.url ? { ...item, ...updatedChannel } : item;
            })
          ),
        };
      } else {
        return {
          channels: sortChannels([...state.channels, updatedChannel]),
        };
      }
    }
    case SET_UPCOMING_SHIFTS: {
      const shiftsGroupedByAgentId = action.data;

      return {
        channels: sortChannels(
          state.channels.map((channel) => {
            const shift = shiftsGroupedByAgentId[channel.url.split("_")[1]];
            if (shift) {
              return { ...channel, shift };
            }

            return channel;
          })
        ),
      };
    }
    default:
      return state;
  }
};

export const setChannels = async (dispatch, channels: GroupChannel[]) => {
  dispatch({
    data: channels,
    type: SET_CHANNELS,
  });

  if (!channels.length) {
    return;
  }

  fetchAndSetUpcomingShifts(dispatch);
};

export const fetchAndSetUpcomingShifts = async (dispatch) => {
  const shiftsGroupedByFacilityId = await getUpcomingShiftsGrouped();

  dispatch({
    type: SET_UPCOMING_SHIFTS,
    data: shiftsGroupedByFacilityId,
  });
};

export const updateChannel = (dispatch, channel) => {
  dispatch({
    data: channel,
    type: UPDATE_CHANNEL,
  });
};
