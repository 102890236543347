import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import Message from "@sendbird/uikit-react/Channel/components/Message";
import { EveryMessage } from "@sendbird/uikit-react/types/types";

export function PlacementApplicationInvite({ message }: { message: EveryMessage }) {
  return (
    <Stack spacing={2}>
      <Text variant="body2" italic>
        This is an automated message sent on behalf of your facility
      </Text>
      <Message message={message} />
    </Stack>
  );
}
