import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { environmentConfig } from "@src/appV2/environment";
import { remove } from "@src/appV2/api";
import { z } from "zod";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";

interface CancelPlacementApplicationAutoInviteParams {
  placementApplicationId: string;
  workplaceId: string;
}

export function useCancelPlacementApplicationAutoInvite(
  options: UseMutationOptions<void, string, CancelPlacementApplicationAutoInviteParams> = {}
) {
  return useMutation({
    mutationFn: async (params: CancelPlacementApplicationAutoInviteParams) => {
      const { placementApplicationId, workplaceId } = params;

      await remove({
        url: `${environmentConfig.REACT_APP_API_MAIN_URL}/api/workplaces/${workplaceId}/placement-applications/${placementApplicationId}/interviews/auto-invites`,
        responseSchema: z.unknown(), // This API returns 204 No Content, with an empty body
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_AUTO_INVITE_CANCEL_FAILURE,
      userSuccessMessage: "Your message was cancelled successfully.",
      userErrorMessage: "Failed to cancel message",
    },
    ...options,
  });
}
