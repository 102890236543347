import { Box, Button, Stack } from "@mui/material";
import { ChatChannel } from "../chat.types";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getLocation } from "@src/utils/routes";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { PlacementDialog } from "@src/appV2/Placements/components/PlacementDialog";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useSession } from "@src/appV2/Auth/api/useSession";
import { getCandidateUrlPath, getUserType } from "@src/appV2/PlacementCandidates/utils";
import { PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS } from "@src/appV2/PlacementCandidates/utils";

interface PlacementChatHeaderProps {
  currentChannel: ChatChannel;
  channelUrl: string;
  backAction?: boolean;
}

export function PlacementChatHeader(props: PlacementChatHeaderProps) {
  const { admin, profile, user } = useSession();
  const { currentChannel, backAction, channelUrl } = props;
  const placementListingModalState = useModalState();
  const history = useHistory();
  const channelMetadata = currentChannel.metadata;

  const [workplaceId, workerId, placementId] = channelUrl.split("_") as Array<string | undefined>;
  const eventContext = {
    workplaceId,
    managingWorkplaceId: channelMetadata?.managingFacilityId,
    workerId,
    placementId,
    userId: user?._id,
    userType: getUserType(profile, admin),
    userEmail: user?.email,
    source: "chatHeader",
  };

  return (
    <>
      <Box className="custom-header" sx={{ width: "100%" }}>
        <Box className="custom-header-title" sx={{ width: "100%" }}>
          {backAction && (
            <ArrowLeftOutlined
              className="close-channel"
              onClick={() => history.push(getLocation("facilityChat"))}
            />
          )}

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              <Text variant="body1" color="text.primary" bold>
                {[channelMetadata?.hcpName, channelMetadata?.agentReq].filter(Boolean).join(", ")}
              </Text>
              <Text variant="body1" color="text.primary">
                Job listing: {channelMetadata?.placementTitle}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="flex-start" gap={1} flexWrap="wrap">
              {channelMetadata?.placementCandidateAccessId && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    logEvent(APP_V2_USER_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                      ...eventContext,
                      action: "viewProfileButtonClick",
                    });
                    window.open(
                      getCandidateUrlPath(
                        channelMetadata.placementCandidateAccessId!,
                        PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_CHAT_HEADER
                      ),
                      "_blank"
                    );
                  }}
                >
                  <Text variant="caption" color={(theme) => theme.palette.common.white}>
                    View profile
                  </Text>
                </Button>
              )}
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                    ...eventContext,
                    action: "viewListingButtonClick",
                  });
                  placementListingModalState.openModal();
                }}
              >
                <Text variant="caption" color={(theme) => theme.palette.text.primary}>
                  View listing
                </Text>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
      {placementListingModalState.modalIsOpen && channelMetadata?.placementId && (
        <PlacementDialog
          modalState={placementListingModalState}
          placementId={channelMetadata?.placementId}
        />
      )}
    </>
  );
}
