import { RenderMessageProps } from "@sendbird/uikit-react/types/types";
import { z } from "zod";

export enum MessageCustomTypes {
  SHIFT_REVIEW = "SHIFT_REVIEW",
  FCM_REQUEST = "FCM_REQUEST",
  FCM_APPROVAL = "FCM_APPROVAL",
  FCM_REJECT = "FCM_REJECT",
  INPUT_ETA = "INPUT_ETA",
  INPUT_ETA_SUBMITTED = "INPUT_ETA_SUBMITTED",
  SHIFT_INVITE_REQUEST = "SHIFT_INVITE_REQUEST",
  SHIFT_INVITE_RESPONSE = "SHIFT_INVITE_RESPONSE",
  PLACEMENT_APPLICATION = "PLACEMENT_APPLICATION",
  PLACEMENT_APPLICATION_INVITE = "PLACEMENT_APPLICATION_INVITE",
  PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE = "PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE",
}

export type RenderCustomMessageProps = Pick<RenderMessageProps, "message">;

export const createChatChannelRequestSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
});
export type CreateChatChannelRequest = z.infer<typeof createChatChannelRequestSchema>;

export const createChatChannelResponseSchema = z.any();
export type CreateChatChannelResponse = z.infer<typeof createChatChannelResponseSchema>;
