import { JobType, ShiftNameType } from "../types";

export const HIRE_WORKERS_PAGE_VIEW_COUNT = "hireWorkersPageViewCount";
export const HIRE_WORKERS_ALERT_VIEW_COUNT = "hireWorkersAlertViewCount";
export const TEMPORARY_RESOURCE_ACCESS_OTP = "resource-access-one-time-password";
export const TEMPORARY_RESOURCE_ACCESS_EMAIL = "temporary-resource-access-email";
export const WHITELISTED_EMAILS = "placement-candidate-whitelisted-emails";
export const DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER = {
  CNA: "CNA",
  "LVN/LPN": "LVN",
  RN: "RN",
  Caregiver: "CAREGIVER",
  HHA: "HHA",
  CHHA: "CHHA",
  "Dental Hygienist": "Dental Hygienist",
  "Dental Assistant": "Dental Assistant",
};
export const DEFAULT_DISTANCE_IN_MILES = 150;
export const GUEST_PLACEMENT_CANDIDATE_EMAIL = "guest-placement-candidate-email";

export const jobTypeOptions = [
  { label: "Full-time", value: JobType.FULL_TIME },
  { label: "Part-time", value: JobType.PART_TIME },
  { label: "PRN", value: JobType.PER_DIEM },
  { label: "Weekend Warrior", value: JobType.WEEKEND_WARRIOR },
];

export const shiftTypeOptions = [
  { label: "Day", value: ShiftNameType.AM },
  { label: "Evening", value: ShiftNameType.PM },
  { label: "Overnight", value: ShiftNameType.NOC },
];

// Limited to New Jersey & Pennsylvania for now
export const workerLicenseStatesOptions = [
  { label: "New Jersey", value: "New Jersey" },
  { label: "Pennsylvania", value: "Pennsylvania" },
];

const PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS = {
  APPLICATIONS: "applications",
  CHAT: "chat",
  TALENTPOOL: "talentPool",
  PLACEMENTCANDIDATESHARE: "placementCandidateShare",
} as const;

export type PlacementCandidatesLinkSourcePagesKeys =
  typeof PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS[keyof typeof PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS];

export const PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS = {
  PLACEMENT_APPLICATIONS_LIST_CARD: "placement-applications-list-card",
  PLACEMENT_CANDIDATE_APPLICATIONS_NAV: "placement-candidate-applications-nav",
  PLACEMENT_CANDIDATE_SHARE_BUTTON: "placement-candidate-share-button",
  PLACEMENT_CANDIDATE_SHARE_FORM: "placement-candidate-share-form",
  PLACEMENT_CANDIDATE_CHAT_MESSAGE: "placement-candidate-chat-message",
  PLACEMENT_CANDIDATE_CHAT_HEADER: "placement-candidate-chat-header",
  BROWSE_TALENT_POOL_PLACEMENT_CANDIDATE_CARD: "browse-talent-pool-placement-candidate-card",
  BROWSE_TALENT_POOL_PLACEMENT_CANDIDATE_CARD_RIGHT_CORNER_ICON:
    "browse-talent-pool-placement-candidate-card-right-corner-icon",
  ADMIN_PLACEMENT_CANDIDATE_PROFILE_LINK: "admin-placement-candidate-profile-link",
} as const;

export type PlacementCandidatesLinkSourceEvent =
  typeof PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS[keyof typeof PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS];

export const PLACEMENT_CANDIDATES_LINK_SOURCES_PAGES: Record<
  PlacementCandidatesLinkSourcePagesKeys,
  PlacementCandidatesLinkSourceEvent[]
> = {
  [PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS.APPLICATIONS]: [
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_APPLICATIONS_LIST_CARD,
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_APPLICATIONS_NAV,
  ],
  [PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS.CHAT]: [
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_CHAT_HEADER,
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_CHAT_MESSAGE,
  ],
  [PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS.TALENTPOOL]: [
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.BROWSE_TALENT_POOL_PLACEMENT_CANDIDATE_CARD,
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.BROWSE_TALENT_POOL_PLACEMENT_CANDIDATE_CARD_RIGHT_CORNER_ICON,
  ],
  [PLACEMENT_CANDIDATES_LINK_SOURCE_PAGES_KEYS.PLACEMENTCANDIDATESHARE]: [
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_SHARE_BUTTON,
    PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_SHARE_FORM,
  ],
} as const;

export function getQualificationLabel(value: string) {
  const entry = Object.entries(DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER).find(
    ([, filterValue]) => filterValue === value
  );
  return entry?.[0] ?? value;
}

export const ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];
