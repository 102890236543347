import { z } from "zod";
import { PLACEMENT_PERKS, TIME_SLOTS, PlacementStatus } from "./createPlacement.schema";
import { API_TYPES } from "@src/appV2/api";
import { JobType } from "@src/appV2/PlacementCandidates/types";

export const GetPlacementsForWorkplaceQuerySchema = z.object({
  page: z
    .object({
      size: z.number().optional(),
      cursor: z.string().optional(),
    })
    .optional(),
});
export type GetPlacementsForWorkplaceQuery = z.infer<typeof GetPlacementsForWorkplaceQuerySchema>;

export const PlacementDataSchema = z.object({
  type: z.literal(API_TYPES.placement),
  id: z.string(),
  attributes: z.object({
    status: z.nativeEnum(PlacementStatus),
    title: z.string(),
    description: z.string().optional(),
    hiringPOC: z.object({
      email: z.string(),
      name: z.string(),
    }),
    jobTypes: z.array(z.enum(Object.values(JobType) as [JobType, ...JobType[]])),
    shiftTypes: z.array(z.enum(TIME_SLOTS.map((slot) => slot.value) as [string, ...string[]])),
    perks: z.array(z.enum(PLACEMENT_PERKS.map((perk) => perk.value) as [string, ...string[]])),
    workerTypes: z.array(z.string()),
    validUntil: z.coerce.date().optional(),
    originalJobLink: z.string().optional(),
    payRange: z
      .object({
        minPay: z
          .object({
            amountInMinorUnits: z.number(),
            currencyCode: z.string(),
          })
          .optional(),
        maxPay: z
          .object({
            amountInMinorUnits: z.number(),
            currencyCode: z.string(),
          })
          .optional(),
      })
      .optional(),
    signOnBonus: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.string(),
      })
      .optional(),
    distance: z.number().optional(),
    address: z.object({
      formatted: z.string().optional(),
    }),
    coordinates: z.array(z.number()),
  }),
  relationships: z.object({
    workplace: z.object({
      data: z.object({
        type: z.literal(API_TYPES.workplace),
        id: z.string(),
      }),
    }),
    applications: z
      .object({
        data: z.array(
          z.object({
            type: z.literal(API_TYPES.placementApplication),
            id: z.string(),
          })
        ),
      })
      .optional(),
  }),
});

const placementWorkplaceIncludeSchema = z.object({
  type: z.literal(API_TYPES.workplace),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
  }),
});
export type PlacementIncludedWorkplace = z.infer<typeof placementWorkplaceIncludeSchema>;

const placementApplicationIncludeSchema = z.object({
  type: z.literal(API_TYPES.placementApplication),
  id: z.string(),
  attributes: z.object({
    appliedDate: z.string(),
  }),
});
export type PlacementIncludedApplication = z.infer<typeof placementApplicationIncludeSchema>;

export const placementIncludeSchema = z.array(
  z.union([placementWorkplaceIncludeSchema, placementApplicationIncludeSchema])
);

export const paginatedGetPlacementsResponseSchema = z.object({
  data: z.array(PlacementDataSchema),
  included: placementIncludeSchema,
  links: z.object({
    nextCursor: z.string().optional(),
  }),
});
export type GetPlacementsResponse = z.infer<typeof paginatedGetPlacementsResponseSchema>;

export const PlacementResponseSchema = z.object({
  data: PlacementDataSchema,
  included: placementIncludeSchema,
});
export type PlacementResponse = z.infer<typeof PlacementResponseSchema>;
