import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

const CLIPBOARD_SCORE = "Clipboard Score";
const ATTENDANCE_SCORE = "Attendance Score";

export function useClipboardScore() {
  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.USE_CLIPBOARD_SCORE, {
    defaultValue: false,
  });
  const scoreText = isClipboardScoreEnabled ? CLIPBOARD_SCORE : ATTENDANCE_SCORE;

  return {
    enabled: isClipboardScoreEnabled,
    scoreText,
  };
}
