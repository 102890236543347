import { ShiftTypeWithTime, TimeObject } from "./postShift.types";
import { ShiftType } from "./postShift.schema";
import {
  add,
  addDays,
  addHours,
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  set,
  setHours,
  setMinutes,
} from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import { DefaultShiftTimes } from "src/containers/facilityDashboard/ShiftCalendar/api";
import { getFlooredHourMinuteDuration } from "../facilityDashboard/ShiftCalendar/newShift/shiftDate";
import { RestrictedShiftCreationError } from "./errors";
import { isDefined } from "@clipboard-health/util-ts";
import { AxiosError } from "axios";
import { z } from "zod";
import moment from "moment-timezone";
import type { ShiftScheduleDo } from "@src/appV2/ShiftSchedule/api/useCreateShiftSchedule";

// deviation from standard AM/PM/NOC start time in hours
export const ALLOWED_DEVIATION = 6;

export function getShiftTypeTimes(shiftTime: ShiftTypeWithTime) {
  const startTime = setMinutes(setHours(new Date(), shiftTime.start.hour), shiftTime.start.minute);
  const endTime = addMinutes(
    addHours(startTime, shiftTime.duration.hours),
    shiftTime.duration.minutes
  );

  return { startTime, endTime };
}

export function getShiftSubmitText(count: number): string {
  const prefix = +count === 1 ? "shift" : "shifts";
  return `${count} ${prefix}`;
}

export function calculateDuration(start: Date | null, end: Date | null) {
  if (start && end) {
    // Make sure the start and end are on the same day
    const endWithStartDay = set(end, {
      year: start.getFullYear(),
      month: start.getMonth(),
      date: start.getDate(),
    });

    const adjustedEnd = isBefore(endWithStartDay, start)
      ? addDays(endWithStartDay, 1)
      : endWithStartDay;
    const minutes = differenceInMinutes(adjustedEnd, start);

    return getFlooredHourMinuteDuration(minutes);
  }
}

export function calculateDeviation(
  start: Date | null,
  end: Date | null,
  shiftType: ShiftType,
  shiftTimes?: DefaultShiftTimes
) {
  if (start && end && shiftTimes) {
    const { startTime } = getShiftTypeTimes(shiftTimes[shiftType]);
    const hours = Math.abs(differenceInHours(startTime, start));
    if (hours > 12) {
      return 24 - hours;
    } else {
      return hours;
    }
  } else {
    return 0;
  }
}

export function formatTime(time?: TimeObject) {
  if (!time) {
    return "";
  }
  const { hours, minutes } = time;
  const timeParts: string[] = [];

  if (hours > 0) {
    const formattedHours = String(hours).replace(/^0+/, "");
    const hourString = `${formattedHours} hr${hours !== 1 ? "s" : ""}`;
    timeParts.push(hourString);
  }

  if (minutes > 0) {
    const formattedMinutes = String(minutes).replace(/^0+/, "");
    const minuteString = `${formattedMinutes} min${minutes !== 1 ? "s" : ""}`;
    timeParts.push(minuteString);
  }

  return timeParts.join(" ");
}

/**
 * Build start and end times for a shift.
 *
 * This is needed to make a shift startAt and endAt with correct timezone, as all dates are in the browser's timezone.
 */
export function getShiftTimes(
  day: Date,
  startTime: Date,
  endTime: Date,
  timezone: string
): { startAt: Date; endAt: Date } {
  // Extract parts of the date and time
  const dayString = format(day, "yyyy-MM-dd");
  const startTimeString = format(startTime, "HH:mm");
  const endTimeString = format(endTime, "HH:mm");

  // Force the dates to be in a certain timezone and then convert to UTC
  const startDateInUtc = zonedTimeToUtc(`${dayString}T${startTimeString}`, timezone);
  let endDateInUtc = zonedTimeToUtc(`${dayString}T${endTimeString}`, timezone);

  // Handle overnight shifts by adding a day to end time if it's before start time
  if (isBefore(endDateInUtc, startDateInUtc)) {
    endDateInUtc = add(endDateInUtc, { days: 1 });
  }

  return {
    startAt: startDateInUtc,
    endAt: endDateInUtc,
  };
}

export function isShiftStartWithTzBeforeNow(startDate: Date, startTime: Date | null, tmz: string) {
  if (!startTime) {
    return false;
  }

  const startDateInUtc = zonedTimeToUtc(
    `${format(startDate, "yyyy-MM-dd")}T${format(startTime, "HH:mm")}`,
    tmz
  );
  const startDateWithTz = new Date(
    formatInTimeZone(startDateInUtc, tmz, "yyyy-MM-dd'T'HH:mm:00XXX")
  );
  const nowWithTz = new Date(formatInTimeZone(new Date(), tmz, "yyyy-MM-dd'T'HH:mm:00XXX"));
  return isBefore(startDateWithTz, nowWithTz);
}

export function getDatesInRange(
  start: Date | null,
  end: Date | null | undefined,
  daysOfWeek: string[]
): Date[] {
  const dates: Date[] = [];
  if (!start || !end || isAfter(start, end)) {
    return dates;
  }
  const interval = { start, end };
  eachDayOfInterval(interval).forEach((currentDate) => {
    if (daysOfWeek.includes(format(currentDate, "e"))) {
      dates.push(currentDate);
    }
  });
  return dates;
}

export function checkShiftCreationError(error: unknown, type: RestrictedShiftCreationError) {
  return error?.["response"]?.["body"]?.["error"] === type;
}

const newErrorsSchema = z.array(
  z.object({
    status: z.string(),
    code: z.string(),
    title: z.string(),
    detail: z.string().optional(),
  })
);

export function extractErrorFromNewApiResponse(error: unknown) {
  if (!(error instanceof AxiosError) || !error.response || !("errors" in error.response.data)) {
    return undefined;
  }

  const parseResult = newErrorsSchema.safeParse(error.response.data.errors);

  return parseResult.success ? parseResult.data : undefined;
}

export function extractErrorFromApiResponse(responseBody?: unknown) {
  if (
    !responseBody ||
    typeof responseBody !== "object" ||
    !("errors" in responseBody) ||
    !Array.isArray(responseBody.errors)
  ) {
    return undefined;
  }

  const errorList = responseBody.errors
    .map((error) => {
      if (!error.status || !error.detail) {
        return undefined;
      }

      return { status: error.status, detail: error.detail };
    })
    .filter((item) => isDefined(item));

  if (errorList.length === 0) {
    return undefined;
  }

  return errorList;
}

// Inform the users that shifts with rush fee were created, example message:
//  2 x PM shift on 7 Jan, 2025
export function getRushFeeMessages(bulkShiftResponse: ShiftScheduleDo): string[] {
  const { shifts, schedule } = bulkShiftResponse;
  const { timeSlot } = schedule;

  // shifts with rush fee grouped by their startAt
  const shiftsWithRushFee = shifts
    .filter((shift) => shift.charges.rushFeeDifferential)
    .reduce(
      (acc, shift) => acc.set(shift.schedule.startAt, (acc.get(shift.schedule.startAt) || 0) + 1),
      new Map<string, number>()
    );

  return Array.from(shiftsWithRushFee.entries()).map(
    ([startAt, total]) => `${total} x ${timeSlot} shift on ${moment(startAt).format("D MMM, YYYY")}`
  );
}
