import { environmentConfig } from "@src/appV2/environment";
import {
  PLACEMENT_CANDIDATES_LINK_SOURCES_PAGES,
  PlacementCandidatesLinkSourceEvent,
  PlacementCandidatesLinkSourcePagesKeys,
} from "./constant";
import { History } from "history";

function findLinkSourcePage(linkSource: PlacementCandidatesLinkSourceEvent) {
  const pageNames = Object.keys(
    PLACEMENT_CANDIDATES_LINK_SOURCES_PAGES
  ) as PlacementCandidatesLinkSourcePagesKeys[];
  return pageNames.find((page) =>
    PLACEMENT_CANDIDATES_LINK_SOURCES_PAGES[page].includes(linkSource)
  );
}

export function getCandidateUrlPath(
  accessId: string,
  linkSource: PlacementCandidatesLinkSourceEvent,
  metadata?: Record<string, string | boolean>
) {
  const metadataSource = {
    linkSourceEvent: findLinkSourcePage(linkSource),
    linkSourceBrowserPath: window.location.pathname,
    ...metadata,
  };

  const metadataSourceInBase64 = btoa(JSON.stringify(metadataSource));
  return `/workplace/hire-professionals/${accessId}?noScroll=true&linkSource=${linkSource}&metadataSource=${metadataSourceInBase64}`;
}

export function getCandidateUrl(
  accessId: string,
  linkSource: PlacementCandidatesLinkSourceEvent,
  metadata?: Record<string, string>
) {
  const urlPath = getCandidateUrlPath(accessId, linkSource, metadata);
  return `${environmentConfig.REACT_APP_ADMIN_APP_CLIENT_URL}${urlPath}`;
}

export function consumeUrlSearchParameter(
  parameterName: string,
  urlSearchParams: URLSearchParams,
  history: History
) {
  const parameter = urlSearchParams.get(parameterName);
  urlSearchParams.delete(parameterName);

  history.replace({
    pathname: location.pathname,
    search: urlSearchParams.toString(),
  });

  return parameter ?? undefined;
}

export function consumeMetadataSource(urlSearchParams: URLSearchParams, history: History) {
  const metadataSource = consumeUrlSearchParameter("metadataSource", urlSearchParams, history);
  if (!metadataSource) {
    return undefined;
  }

  return JSON.parse(atob(metadataSource)) as Record<string, string>;
}
