import { CardActions, Stack } from "@mui/material";
import { CbhCustomChatMessage } from "@src/appV2/Chat/CbhCustomChatMessage";
import { CoreMessageType } from "@sendbird/uikit-react/types/utils";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { ChatChannel } from "../chat.types";
import { z } from "zod";
import { Text } from "@clipboard-health/ui-react";
import { Colors } from "@src/utils/colors";
import { Check, Block } from "@mui/icons-material";
import { useCancelPlacementApplicationAutoInvite } from "../useCancelPlacementApplicationAutoInvite";
import { useInvitePlacementApplicationCandidateToInterview } from "../useInvitePlacementApplicationCandidateToInterview";
import { useSession } from "@src/appV2/Auth/api/useSession";
import { getUserType } from "@src/appV2/PlacementCandidates/utils/helper";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

const placementApplicationAutoInterviewInviteSchema = z.object({
  futureMessage: z.object({
    message: z.string(),
    status: z.enum(["queued", "cancelled", "sent"]),
  }),
  placementApplicationId: z.string(),
});

export function PlacementApplicationAutoInterviewInvite({
  message,
  currentChannel,
  channelUrl,
}: {
  message: CoreMessageType;
  currentChannel: ChatChannel;
  channelUrl: string;
}) {
  const { admin, profile, user } = useSession();
  const { managingFacilityId: workplaceId } = currentChannel.metadata ?? {};
  const chatMessage = message as CoreMessageType & { message: string };
  const { mutateAsync: cancelInvitationMessage } = useCancelPlacementApplicationAutoInvite();
  const { mutateAsync: inviteCandidateToInterview } =
    useInvitePlacementApplicationCandidateToInterview();

  // sometimes the workplace id is not available in the channel metadata?
  if (!chatMessage.message || !workplaceId) {
    return null;
  }

  const parsedData = placementApplicationAutoInterviewInviteSchema.safeParse(
    JSON.parse(chatMessage.data)
  );

  if (!parsedData.success) {
    return null;
  }

  const [trueWorkplaceId, workerId, placementId] = channelUrl.split("_") as Array<
    string | undefined
  >;
  const eventContext = {
    workplaceId: trueWorkplaceId,
    managingWorkplaceId: workplaceId,
    workerId,
    placementId,
    userId: user?._id,
    userType: getUserType(profile, admin),
    userEmail: user?.email,
    source: "chatBody",
  };

  const { futureMessage, placementApplicationId } = parsedData.data;

  return (
    <CbhCustomChatMessage
      message={chatMessage.message}
      avatarSxProps={{ justifyContent: "flex-start" }}
      messageContainerSxProps={{ width: "50%" }}
    >
      <CardActions>
        <Stack sx={{ padding: 1 }} spacing={2} alignItems="flex-start">
          <Box>
            <Text variant="body2" italic>
              {futureMessage.message}
            </Text>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {futureMessage.status === "queued" && (
              <>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    logEvent(APP_V2_USER_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                      ...eventContext,
                      action: "interviewSendNowButtonClick",
                    });
                    inviteCandidateToInterview({ placementApplicationId, workplaceId });
                  }}
                >
                  Send now
                </Button>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    logEvent(APP_V2_USER_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                      ...eventContext,
                      action: "interviewDontSendButtonClick",
                    });
                    cancelInvitationMessage({ placementApplicationId, workplaceId });
                  }}
                >
                  Don&apos;t send
                </Button>
              </>
            )}
            {futureMessage.status === "cancelled" && (
              <>
                <Block style={{ color: Colors.danger }} />
                <Text
                  variant="body2"
                  style={{
                    color: Colors.danger,
                  }}
                >
                  Message cancelled
                </Text>
              </>
            )}
            {futureMessage.status === "sent" && (
              <>
                <Check style={{ color: Colors.success }} />
                <Text
                  variant="body2"
                  style={{
                    color: Colors.success,
                  }}
                >
                  Message sent
                </Text>
              </>
            )}
          </Stack>
        </Stack>
      </CardActions>
    </CbhCustomChatMessage>
  );
}
