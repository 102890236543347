import { API_TYPES } from "@src/appV2/api";
import { JobType } from "@src/appV2/PlacementCandidates/types";
import { z } from "zod";

export const TIME_SLOTS = [
  { value: "am", label: "Day" },
  { value: "pm", label: "Evening" },
  { value: "noc", label: "Overnight" },
] as const;
export type TimeSlot = typeof TIME_SLOTS[number]["value"];

export const PLACEMENT_STATUS = [
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "closed",
    label: "Paused",
  },
  {
    value: "archived",
    label: "Archived",
  },
] as const;
export enum PlacementStatus {
  DRAFT = "draft",
  OPEN = "open",
  PAUSED = "closed",
  ARCHIVED = "archived",
}

export const PLACEMENT_STATUS_DEFAULT_FILTER = [
  PlacementStatus.DRAFT,
  PlacementStatus.OPEN,
  PlacementStatus.PAUSED,
];

export const PLACEMENT_PERKS = [
  {
    value: "shiftDifferential",
    label: "Shift Differential",
  },
  {
    value: "dailyPay",
    label: "Daily Pay",
  },
  {
    value: "twelveShiftsAvailable",
    label: "12-Hour Shifts Available",
  },
  {
    value: "doublesAvailable",
    label: "Double Shifts Available",
  },
  {
    value: "signOnBonus",
    label: "Sign On Bonus",
  },
  {
    value: "overtimeAvailable",
    label: "Overtime Available",
  },
] as const;

export type PlacementPerk = typeof PLACEMENT_PERKS[number]["value"];

export const createPlacementBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.placement),
    attributes: z
      .object({
        workplaceId: z.string(),
        title: z.string(),
        description: z.string().optional(),
        hiringPOC: z
          .object({
            email: z.string().optional(),
            name: z.string().optional(),
          })
          .optional(),
        jobTypes: z.array(z.enum(Object.values(JobType) as [JobType, ...JobType[]])),
        shiftTypes: z.array(z.enum(TIME_SLOTS.map((slot) => slot.value) as [string, ...string[]])),
        perks: z.array(z.enum(PLACEMENT_PERKS.map((perk) => perk.value) as [string, ...string[]])),
        workerTypes: z.array(z.string()),
        validUntil: z.coerce.date().optional(),
        originalJobLink: z.string().optional(),
        payRange: z
          .object({
            minPay: z
              .object({
                amountInMinorUnits: z.coerce.number(),
                currencyCode: z.string(),
              })
              .optional(),
            maxPay: z
              .object({
                amountInMinorUnits: z.coerce.number(),
                currencyCode: z.string(),
              })
              .optional(),
          })
          .optional(),
        signOnBonus: z
          .object({
            amountInMinorUnits: z.coerce.number(),
            currencyCode: z.string(),
          })
          .optional(),
      })
      .strict(),
  }),
});

export type CreatePlacementRequest = z.infer<typeof createPlacementBodySchema>;

export const createPlacementFormSchema = z.object({
  workplaceId: z.string(),
  title: z
    .string({
      required_error: "Enter a job title",
    })
    .min(1, { message: "Enter a job title" }),
  description: z.string().optional(),
  workerTypes: z.array(z.string()).min(1, { message: "Select at least one professional type" }),
  hiringPOC: z.object({
    email: z
      .string({
        required_error: "Enter hiring manager's email",
      })
      .email({ message: "Enter a valid email address" }),
    name: z
      .string({
        required_error: "Enter hiring manager's full name",
      })
      .min(1, { message: "Enter hiring manager's full name" }),
  }),
  jobTypes: z
    .array(z.enum(Object.values(JobType) as [JobType, ...JobType[]]))
    .min(1, { message: "Select at least one job type" }),
  shiftTypes: z
    .array(z.enum(TIME_SLOTS.map((slot) => slot.value) as [string, ...string[]]))
    .min(1, { message: "Select at least one shift type" }),
  perks: z.array(z.enum(PLACEMENT_PERKS.map((perk) => perk.value) as [string, ...string[]])),
  validUntil: z.date().optional(),
  originalJobLink: z
    .string()
    .optional()
    .refine((value) => !value || z.string().url().safeParse(value).success, {
      message: "Enter a valid http(s) URL like https://example.com/job-posting",
    }),
  payRange: z
    .object({
      minPay: z.coerce
        .number()
        .min(0, { message: "Minimum pay must be greater than 0" })
        .optional(),
      maxPay: z.coerce
        .number()
        .min(0, { message: "Minimum pay must be greater than 0" })
        .optional(),
    })
    .refine((data) => !data.minPay || !data.maxPay || data.minPay <= data.maxPay, {
      message: "Maximum pay should be greater than minimum pay",
      path: ["maxPay"],
    })
    .optional(),
  signOnBonus: z
    .object({
      amount: z.coerce.number().min(0, { message: "Amount must be greater than 0" }).optional(),
    })
    .optional(),
});

export type CreatePlacementForm = z.infer<typeof createPlacementFormSchema>;
