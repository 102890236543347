import { Box, Button, CardActions, Stack } from "@mui/material";

import { CbhCustomChatMessage } from "@src/appV2/Chat/CbhCustomChatMessage";
import { ChatChannel } from "../chat.types";
import { CoreMessageType } from "@sendbird/uikit-react/types/utils";
import { Text } from "@clipboard-health/ui-react";
import { getUserType } from "@src/appV2/PlacementCandidates/utils/helper";
import { useSession } from "@src/appV2/Auth/api/useSession";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { getCandidateUrlPath } from "@src/appV2/PlacementCandidates/utils";
import { PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS } from "@src/appV2/PlacementCandidates/utils";

interface PlacementApplicationMessageProps {
  message: CoreMessageType;
  currentChannel: ChatChannel;
  channelUrl: string;
}

export function PlacementApplicationMessage({
  message,
  currentChannel,
  channelUrl,
}: PlacementApplicationMessageProps) {
  const { admin, profile, user } = useSession();
  const { placementCandidateAccessId, managingFacilityId } = currentChannel.metadata ?? {};
  const chatMessage = message as CoreMessageType & { message: string };
  if (!chatMessage.message) {
    return undefined as unknown as React.ReactElement;
  }
  const { hcpName, agentReq, placementTitle, facilityName } = currentChannel.metadata ?? {};

  const [workplaceId, workerId, placementId] = channelUrl.split("_") as Array<string | undefined>;
  const eventContext = {
    workplaceId,
    managingWorkplaceId: managingFacilityId,
    workerId,
    placementId,
    userId: user?._id,
    userType: getUserType(profile, admin),
    userEmail: user?.email,
    source: "chatBody",
  };

  const messageToDisplay =
    !hcpName || !placementTitle || !facilityName
      ? chatMessage.message
      : `${[hcpName, agentReq]
          .filter(Boolean)
          .join(
            ", "
          )} has applied for the ${placementTitle} opening at ${facilityName}. View their candidate profile to check their resume, license and pre-employment credentials.`;
  return (
    <CbhCustomChatMessage
      message={messageToDisplay}
      avatarSxProps={{ justifyContent: "flex-start" }}
      messageContainerSxProps={{ width: "50%" }}
    >
      <CardActions>
        <Stack sx={{ padding: 1 }} spacing={2} alignItems="flex-start">
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {placementCandidateAccessId && (
              <Button
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                    ...eventContext,
                    action: "viewProfileButtonClick",
                  });
                  window.open(
                    getCandidateUrlPath(
                      placementCandidateAccessId,
                      PLACEMENT_CANDIDATES_LINK_SOURCE_EVENTS.PLACEMENT_CANDIDATE_CHAT_MESSAGE
                    ),
                    "_blank"
                  );
                }}
                variant="contained"
              >
                View profile
              </Button>
            )}
          </Stack>
          <Box>
            <Text variant="body2">
              You can use this channel to speak to the candidate directly and invite them to an
              interview.
            </Text>
          </Box>
        </Stack>
      </CardActions>
    </CbhCustomChatMessage>
  );
}
