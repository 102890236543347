import { getAnonymousLdUser, getSignedInLdUser } from "@src/appV2/FeatureFlags/cbhUserContext";
import { SessionType } from "@src/modules/interface";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

interface InitializeLaunchDarklyProps {
  profile?: SessionType["session"]["profile"];
  isAdmin: boolean;
}

export function useInitializeLaunchDarkly(props: InitializeLaunchDarklyProps) {
  const { profile, isAdmin } = props;
  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    (async () => {
      const ldUser = !profile?.userId
        ? getAnonymousLdUser()
        : getSignedInLdUser({
            userId: profile?.userId ?? "",
            name: profile?.name ?? "",
            email: profile?.email ?? "",
            msa:
              profile?.fullAddress?.metropolitanStatisticalArea ??
              profile?.address?.metropolitanStatisticalArea ??
              "",
            pricingMsa: profile?.pricingMsa ?? "",
            city: profile?.fullAddress?.city ?? "",
            state: profile?.fullAddress?.state ?? "",
            stateCode: profile?.fullAddress?.stateCode ?? "",
            type: profile?.type ?? "",
            hcfWebVersion: __APP_VERSION__,
            userType: !isAdmin ? "WORKPLACE_USER" : "CBH_EMPLOYEE",
          });

      ldClient.identify(ldUser);
    })();
  }, [
    ldClient,
    profile?.userId,
    profile?.name,
    profile?.email,
    profile?.fullAddress?.metropolitanStatisticalArea,
    profile?.pricingMsa,
    profile?.fullAddress?.city,
    profile?.fullAddress?.state,
    profile?.fullAddress?.stateCode,
    profile?.type,
    isAdmin,
  ]);
}
