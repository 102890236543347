import { Fragment, ReactElement, useCallback, useEffect, useState } from "react";

import { Channel, useSendbirdStateContext } from "@sendbird/uikit-react";
import "@sendbird/uikit-react/dist/index.css";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import "./chatStyle.scss";
import { fetchAndSetUpcomingShifts, updateChannel } from "src/modules/chat";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { ChatModalProps, ChatProviderProps, SendbirdGroupChannel } from "./chat.types";
import { useQuery } from "@tanstack/react-query";
import { fetchExclusion } from "src/api/workerReview";
import { getLocation } from "src/utils/routes";
import { SendbirdChat, Session, SessionType } from "src/modules/interface";
import { useActiveChatWindowDays } from "./useActiveChatWindowDays";
import { getLastMessageDate } from "src/utils/sendbird";
import {
  CustomTypesChannelFilter,
  fetchChannels,
  getChannelShiftTime,
  getChannelTitle,
  getLastMessageInfo,
  getMessageInputDefinition,
} from "./channel";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { GroupChannelHandler } from "@sendbird/chat/groupChannel";
import { BaseChannel } from "@sendbird/chat";
import { SendableMessageType } from "@sendbird/uikit-react/types/utils";
import { FileMessageCreateParams, UserMessageCreateParams } from "@sendbird/chat/message";
import { renderSendbirdChannelMessage } from "./ChannelMessage";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { ChatSearchBar } from "./ChatSearchBar";
import { debounce } from "lodash";
import { ChatHeader } from "./components/ChatHeader";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CircularProgress, Stack } from "@mui/material";
import { ChatChannelTypeFilter } from "./components/ChatChannelTypeFilter";
import { PlacementChatHeader } from "./components/PlacementChatHeader";
import { useGetPlacementsAccessLevel } from "@src/appV2/Placements/useGetPlacementsAccessLevel";

const appId = environmentConfig.REACT_APP_SENDBIRD_APP_ID;

export function ChatPage() {
  const { userId, user, admin, profile } = useSelector<SessionType, Session>(
    (state) => state.session
  );

  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const history = useHistory();
  const { agentId, facilityId, placementId } = useParams<{
    agentId: string;
    facilityId: string;
    placementId?: string;
  }>();
  const { channels } = useSelector<SessionType, SendbirdChat>((state) => state.chat);
  const [isChannelsLoading, setIsChannelsLoading] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const { isEnabled: isPlacementsEnabled } = useGetPlacementsAccessLevel();
  const [customTypesFilter, setCustomTypesFilter] = useState<CustomTypesChannelFilter>("all");

  const currentChannelUrl = facilityId
    ? [facilityId, agentId, placementId].filter(Boolean).join("_")
    : "";
  const [currentChannel, setCurrentChannel] = useState<SendbirdGroupChannel | undefined>(undefined);

  useEffect(() => {
    if (isDefined(channels) && isDefined(currentChannelUrl)) {
      setCurrentChannel(channels.find((channel) => channel.url === currentChannelUrl));
    }
  }, [setCurrentChannel, channels, currentChannelUrl]);

  const dispatch = useDispatch();
  const activeChatWindowDays = useActiveChatWindowDays();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchChannels = useCallback(
    debounce(async (props) => {
      try {
        setIsChannelsLoading(true);
        await fetchChannels(props);
      } finally {
        setIsChannelsLoading(false);
      }
    }, 200),
    [fetchChannels]
  );

  useEffect(() => {
    async function fetchAndDispatchChannels() {
      if (sdk && debouncedFetchChannels) {
        debouncedFetchChannels({
          sdk,
          dispatch,
          nicknameContainsFilter: searchQuery,
          customTypesFilter,
        });
      }
    }
    fetchAndDispatchChannels();
  }, [dispatch, sdk, searchQuery, debouncedFetchChannels, customTypesFilter]);

  useEffect(() => {
    if (!admin && facilityId && agentId && user?._id) {
      logEvent(HCF_USER_EVENTS.CHAT_OPENED, {
        location: "chatChannel",
        workerId: agentId,
        workplaceId: facilityId,
        workplaceUserId: user._id,
      });
    }
  }, [facilityId, agentId, user?._id, admin]);

  useEffect(() => {
    if (isDefined(profile?.userId)) {
      setCustomTypesFilter("all");
    }
  }, [profile?.userId]);

  const { isLoading: isLoadingExclusion, data: exclusions } = useQuery(
    ["chat", "exclusion", agentId, facilityId],
    () =>
      fetchExclusion({
        facilityId,
        agentId,
      }),
    {
      enabled: !!(userId && facilityId && agentId),
    }
  );

  const channelClick = (newChannelUrl: string) => {
    const [facilityId, agentId, placementId] = newChannelUrl.split("_");
    setCurrentChannel(channels.find((channel) => channel.url === newChannelUrl));
    history.push(
      getLocation("facilityChatWithAgent", {
        pathParams: { facilityId, agentId, placementId },
      })
    );
  };

  return (
    <Box
      className={"chat-container chat-style"}
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Box className={"chat " + (currentChannelUrl ? "selected-chat" : "")}>
        <Box className="channels-container">
          <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
            <ChatSearchBar searchQuery={searchQuery ?? ""} setSearchQuery={setSearchQuery} />
            {isPlacementsEnabled && (
              <ChatChannelTypeFilter
                customTypesFilter={customTypesFilter}
                onFilterChange={(filterValue) => {
                  setCustomTypesFilter(filterValue);
                  history.replace(getLocation("facilityChat"));
                }}
              />
            )}
          </Box>
          <Box sx={{ flex: 1, overflowY: "auto", height: "100%" }}>
            {isChannelsLoading ? (
              <Stack alignItems="center" height="80%" justifyContent="center">
                <CircularProgress />
              </Stack>
            ) : (
              channels.map((channel, index, array) => {
                const classes = ["channel"];

                if (channel.url === currentChannelUrl) {
                  classes.push("selected");
                }

                if (index > 0) {
                  classes.push("border");
                } // Not First
                if (index === array.length - 1) {
                  classes.push("shadow");
                } // Last

                return (
                  <div
                    tabIndex={0}
                    role="row"
                    key={channel.url}
                    className={classes.join(" ")}
                    onClick={() => channelClick(channel.url)}
                    onKeyUp={() => channelClick(channel.url)}
                  >
                    <div className="channel-name">
                      <b>{getChannelTitle(channel)}</b>
                    </div>
                    <div className="channel-last">{getLastMessageDate(channel)}</div>
                    <div className="channel-message">
                      {getLastMessageInfo(channel, sdk?.currentUser?.userId ?? "")}
                    </div>
                    {channel.unreadMessageCount > 0 ? (
                      <div className="channel-unread">{channel.unreadMessageCount}</div>
                    ) : (
                      <div></div>
                    )}
                    <div className="channel-upcoming-shift">
                      {getChannelShiftTime(channel, activeChatWindowDays)}
                    </div>
                  </div>
                );
              })
            )}
          </Box>
        </Box>

        <Channel
          renderMessage={({ message }) =>
            renderSendbirdChannelMessage({
              message,
              user,
              userId,
              currentChannel,
              channelUrl: currentChannel?.url,
            })
          }
          onBeforeSendFileMessage={
            sdk && user
              ? (file, quoteMessage) => handleBeforeSendFileMessage(user, file, quoteMessage)
              : undefined
          }
          onBeforeSendUserMessage={
            sdk && user
              ? (text, quoteMessage) => handleBeforeSendUserMessage(user, text, quoteMessage)
              : undefined
          }
          renderMessageInput={getMessageInputDefinition({
            isLoadingExclusion,
            sdk,
            actionBy: exclusions?.[0]?.actionBy,
          })}
          channelUrl={currentChannelUrl}
          renderChannelHeader={() =>
            currentChannel?.customType === "placements" ? (
              <PlacementChatHeader
                currentChannel={currentChannel}
                channelUrl={currentChannel.url}
              />
            ) : (
              <ChatHeader
                workerId={agentId}
                workplaceId={facilityId}
                workplaceUserId={user?._id ?? ""}
                currentChannel={currentChannel}
                backAction={true}
                facilityStatus={profile.status}
              />
            )
          }
        />
      </Box>
    </Box>
  );
}

export function ChatModal(props: ChatModalProps) {
  const { channelUrl, closeModal } = props;
  const { channels } = useSelector<SessionType, SendbirdChat>((state) => state.chat);
  const { userId, user, profile } = useSelector<SessionType, Session>((state) => state.session);
  const currentChannel = channels.find((channel) => channel.url === channelUrl);
  const [workplaceId, workerId] = (channelUrl ?? "").split("_"); // channel's url is the concatenation of workplaceId_workerId and optionally placementId
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const { isLoading: isLoadingExclusion, data: exclusions } = useQuery(
    ["chat", "exclusion", workerId, workplaceId],
    () =>
      fetchExclusion({
        facilityId: workplaceId,
        agentId: workerId,
      }),
    {
      enabled: !!(userId && workerId && workplaceId),
    }
  );

  return (
    <Fragment>
      <Modal
        className="chat-style"
        footer={<Fragment />}
        visible={Boolean(channelUrl)}
        bodyStyle={{ height: 688, maxHeight: "78vh", paddingTop: 50 }}
        width={680}
        onCancel={() => closeModal()}
      >
        <div className="chat-container chat-style">
          <Channel
            renderMessage={({ message }) =>
              renderSendbirdChannelMessage({
                message,
                user,
                userId,
                currentChannel,
                channelUrl: currentChannel?.url,
              })
            }
            renderMessageInput={getMessageInputDefinition({
              isLoadingExclusion,
              sdk,
              actionBy: exclusions?.[0]?.actionBy,
            })}
            onBeforeSendFileMessage={
              sdk && user
                ? (file, quoteMessage) => handleBeforeSendFileMessage(user, file, quoteMessage)
                : undefined
            }
            onBeforeSendUserMessage={
              sdk && user
                ? (text, quoteMessage) => handleBeforeSendUserMessage(user, text, quoteMessage)
                : undefined
            }
            channelUrl={channelUrl}
            renderChannelHeader={() =>
              currentChannel?.customType === "placements" ? (
                <PlacementChatHeader
                  currentChannel={currentChannel}
                  channelUrl={currentChannel.url}
                />
              ) : (
                <ChatHeader
                  workerId={workerId}
                  workplaceId={userId}
                  workplaceUserId={user?._id ?? ""}
                  currentChannel={{
                    name: currentChannel?.name ?? "Channel",
                    customType: currentChannel?.customType ?? "",
                    shift: currentChannel?.shift,
                    metadata: {
                      ...currentChannel?.metadata,
                    },
                  }}
                  backAction={false}
                  facilityStatus={profile.status}
                />
              )
            }
          />
        </div>
      </Modal>
    </Fragment>
  );
}

export function ChatProvider(props: ChatProviderProps) {
  const { children } = props;
  const userId = useSelector<SessionType, string | undefined>((store) => store.session.userId);
  const session = useSelector<SessionType, Session>((store) => store.session);
  const sendBirdAccessToken = useSelector<SessionType, string | undefined>(
    (store) => store.session.sendBirdAccessToken
  );
  let sendbirdAppId = appId;

  if (
    !session.profile ||
    session.type === "ADMIN" ||
    session.admin ||
    session.user?.email.includes("@playwright-hcf.com")
  ) {
    sendbirdAppId = "";
  }

  return (
    <SendbirdProvider
      appId={sendbirdAppId ?? ""}
      userId={userId ?? ""}
      nickname={session.profile?.name}
      accessToken={sendBirdAccessToken}
    >
      <ChatProviderWithStore>{children}</ChatProviderWithStore>
    </SendbirdProvider>
  );
}

function ChatProviderWithStore(props: ChatProviderProps): ReactElement {
  const { children } = props;
  const dispatch = useDispatch();
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const isSendbirdConcurrentConnectionOptimisationEnabled = useCbhFlag(
    CbhFeatureFlag.SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION,
    {
      defaultValue: false,
    }
  );

  useEffect(() => {
    if (!sdk?.groupChannel) {
      return;
    }

    const uuid = uuidv4();

    const channelHandler = new GroupChannelHandler({
      onChannelChanged: (channel) => {
        if (!channel.cachedMetaData || Object.keys(channel.cachedMetaData).length === 0) {
          // sendbird will auto fetch a channel when one is created, however it will not include the metadata
          // so we need to refetch the channels to get the metadata
          fetchChannels({ sdk, dispatch });
        } else {
          updateChannel(dispatch, channel);
        }
      },
      onMessageReceived: (_channel: BaseChannel, message) => {
        if (message.isAdminMessage()) {
          fetchAndSetUpcomingShifts(dispatch);
        }
      },
    });

    channelHandler.onUserReceivedInvitation = (channel) => {
      updateChannel(dispatch, channel);
      fetchAndSetUpcomingShifts(dispatch);
    };

    sdk.groupChannel.addGroupChannelHandler(uuid, channelHandler);

    return () => sdk?.groupChannel.removeGroupChannelHandler?.(uuid);
  }, [dispatch, sdk]);

  useEffect(() => {
    async function fetchAndDispatchChannels() {
      if (sdk) {
        await fetchChannels({ sdk, dispatch });
      }
    }
    fetchAndDispatchChannels();
  }, [dispatch, sdk]);

  useEffect(() => {
    // Exit early and avoid adding listeners if the feature flag is turned off
    if (!isSendbirdConcurrentConnectionOptimisationEnabled) {
      return () => null;
    }

    const handleTabChange = async () => {
      if (document.visibilityState === "visible") {
        sdk?.setForegroundState?.();
        await fetchChannels({ sdk, dispatch });
      } else {
        sdk?.setBackgroundState?.();
      }
    };

    const handleWindowChange = async () => {
      if (!document.hasFocus() && sdk?.connectionState === "OPEN") {
        sdk?.setBackgroundState?.();
      }

      if (document.hasFocus() && sdk?.connectionState === "CLOSED") {
        sdk?.setForegroundState?.();
        await fetchChannels({ sdk, dispatch });
      }
    };

    // This is fired when user switches tabs
    // Internally, we check if the tab is focused, then reconnect, otherwise we disconnect
    document.addEventListener("visibilitychange", handleTabChange);

    // Moving away from current window and coming back to it is tracked via blur and focus events
    // Internally, we check if the tab is focused as the window gets focus back and reconnect, otherwise we disconnect
    window.addEventListener("blur", handleWindowChange);
    window.addEventListener("focus", handleWindowChange);

    return () => {
      document.removeEventListener("visibilitychange", handleTabChange);
      window.removeEventListener("blur", handleWindowChange);
      window.removeEventListener("focus", handleWindowChange);
    };
  }, [dispatch, isSendbirdConcurrentConnectionOptimisationEnabled, sdk]);

  return children;
}

function handleBeforeSendUserMessage(
  user: {
    access: string[];
    _id: string;
    email: string;
    name: string;
    permissions?: string[] | undefined;
  },
  text: string,
  quoteMessage?: SendableMessageType
): UserMessageCreateParams {
  const message = typeof text === "string" ? text.trim() : text;
  const params: UserMessageCreateParams = {
    message,
    data: JSON.stringify({
      workplaceUserId: user._id,
      workplaceUserName: user.name,
    }),
  };

  if (quoteMessage) {
    params.isReplyToChannel = true;
    params.parentMessageId = quoteMessage.messageId;
  }

  return params;
}

function handleBeforeSendFileMessage(
  user: {
    access: string[];
    _id: string;
    email: string;
    permissions?: string[] | undefined;
    name: string;
  },
  file: File,
  quoteMessage?: SendableMessageType
): FileMessageCreateParams {
  const params: FileMessageCreateParams = {
    file,
    data: JSON.stringify({
      workplaceUserId: user._id,
      workplaceUserName: user.name,
    }),
  };

  if (quoteMessage) {
    params.isReplyToChannel = true;
    params.parentMessageId = quoteMessage.messageId;
  }

  return params;
}
